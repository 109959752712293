// Perfect scrollbar
@import '~perfect-scrollbar/css/perfect-scrollbar';
@import '~node-waves/src/scss/waves.scss';

// Core styles
@import './base/bootstrap';
@import './base/bootstrap-extended';
@import './base/colors';
@import './base/components';
@import './base/themes/dark-layout';
@import './base/themes/bordered-layout';
@import './base/themes/semi-dark-layout';
@import './base/core/colors/palette-gradient';
@import './base/plugins/forms/form-validation'; // Added for form validation style support

// Vue Specific
@import './angular/index';


.text-bar {
  position: relative;
}

.text-bar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, #fff0 calc(50% - 1px), #ff0000a6, #fff0 calc(50% + 2px) );
}
