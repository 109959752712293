// ================================================================================================
//     File Name: Footer.scss
//     Description: *  Main footer styles.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Stick footer to the bottom of the page
footer {
  &.footer {
    // padding: 0.8rem $content-padding;
    min-height: $footer-height;
    transition: 300ms ease all;

    // heart icon scss
    span {
      i,
      svg {
        height: 1.51rem;
        width: 1.51rem;
        font-size: 1.51rem;
        margin-left: 0.5rem;
        color: $danger;
      }
    }
    .scroll-top {
      padding: 0.81rem 0.83rem;
    }
  }

  .footer-block-divided {
    border-top: 1px solid #ebe9f11f;
  }

  .newsletter-email {
    width: 15rem;
  }
  .newsletter-privacy-policy {
    max-width: 25rem;
    font-size: .8rem;

    label {
      font-size: .8rem;
    }
  }

  .payment-methods {
    display: inline-block;

    .payment-method {
      display: inline-block;
      height: 5rem;
      border-radius: 0.357rem;
      background: white;
      vertical-align: middle;
      cursor: pointer;
      border: 2px solid var(--primary);

      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
      .payment-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: var(--primary)cc;
        transition: width 0.3s ease-in-out;
        overflow: hidden;
        text-overflow: clip;
        word-break: keep-all;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: bold;
        text-decoration: underline;
      }
      &:hover {
        .payment-link {
          width: 100%;
        }
      }

      img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  //shadow & border options
  &.footer-shadow {
    box-shadow: $box-shadow;
  }
  &.footer-border {
    border-top: 1px solid $border-color;
  }
}

// Fixed Footer
.footer-fixed {
  footer {
    position: fixed !important;
    z-index: 1030;
    right: 0;
    left: 0;
    bottom: 0;

    // Footer Light
    &.footer-light {
      background: $white;
      box-shadow: $box-shadow;
    }
    // Footer Dark
    &.footer-dark {
      background: $gray-600;
      color: $white;
    }
  }
}

//! Fix: If footer is hidden add padding fix to avail card margin bottom
.footer-hidden {
  .app-content {
    padding-bottom: 0.1px !important;
  }
}

// scroll to Top Footer Button
.scroll-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  display: none;
  z-index: 99;
}
