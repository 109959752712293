@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

:root {
  --danger-color-rgb: 255, 0, 0;
}

.btn,
.btn-icon,
.btn-flat,
.btn-icon.btn-flat {
  color: #fff !important;
}

.btn-secondary,
.bg-secondary,
.badge-secondary,
.btn-flat-secondary {
  background-color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
  color: #fff;
}

.border-secondary {
  border-color: var(--secondary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.slider-secondary {
  color: var(--secondary) !important;
  background-color: #f1f1f1;
}

.btn-outline-primary {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  background-color: transparent !important;

  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.3) !important;
  }
}

.btn-primary,
.bg-primary,
.badge-primary {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: #fff;
}

.btn-flat-primary {
  background-color: transparent !important;
  color: var(--primary);
}

.border-primary {
  border-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.slider-primary {
  color: var(--primary) !important;
  background-color: #f1f1f1;
}

.green {
  color: green !important;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  background-color: #f5f5f5;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(var(--primary-color-rgb), 0.6);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}


.modal-content {
  z-index: 2000 !important;
}

.modal-backdrop {
  z-index: 999 !important;
}


.u-userLauncherColor:not([disabled]) {
  background-color: var(--primary) !important;
  color: #fff !important;
}


.btn-link {
  background-color: transparent !important;
  color: var(--primary) !important;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
  cursor: auto;
  pointer-events: none;
}


.btn-flat-danger {
  background-color: red !important;
}


a:hover {
  text-decoration: none;
  color: var(--secondary) !important;
}


.text-primary:hover {
  color: var(--primary) !important;
}

#launcher {
  box-shadow: 0 0 10px var(--primary) !important;
  border-radius: 25rem;
  object-fit: cover;
  background-color: var(--secondary) !important;
}


.ngx-datatable {
  .datatable-header {
    height: unset !important;

    .datatable-header-cell-label {
      font-size: medium;
      text-transform: uppercase;
    }
  }

  .datatable-body-cell {
    padding: .2rem .3rem !important;
  }

  // sovrascrive lo stile di ngx-datatable per sfruttare tutto lo spazio a disposizione
  .datatable-scroll,
  .datatable-header-inner,
  .datatable-body-row,
  .datatable-row-center,
  .datatable-body-cell,
  .datatable-header-cell {
    width: 100% !important;
  }
}
